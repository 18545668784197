<template>
  <v-card style="height: 100%">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <ul class="task-list">
        <li v-for="train in trainsData" :key="train.id">
          <v-row>
            <v-col cols="7">
              <span class="task-name" style="display: inline-table">
                {{ viewNomeViaggio() ? train.nome : train.codice }}: {{ train.nodoPartenza.codice }} - {{ train.nodoArrivo.codice }}
              </span>
            </v-col>
            <v-col cols="3"
              ><span style="text-align: right; font-weight: bold; display: inline-table">
                {{ getTimeFromDate(train.eta) }} - {{ getTimeFromDate(train.etd) }}
                &nbsp;
                <v-icon>{{ iconDescription }}</v-icon>
              </span>
            </v-col>
            <v-col cols="2">
              <span style="text-align: right; font-weight: bold; display: inline-table">
                {{ getUtiCaricateOrScaricate(train) }}/{{ train.numeroUti }}
              </span>
            </v-col>
          </v-row>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import railsMixins from "../rails/mixins/railsMixins";

export default {
  name: "trainJourneysPanel",
  data() {
    return {
      hours: 24,
    };
  },
  props: {
    title: {
      type: String,
      default: "Tasks",
    },
    trainsData: {
      type: Array,
      default: () => [],
    },
    iconDescription: {
      type: String,
      default: "pi pi-info",
    },
  },
  mixins: [railsMixins],
  methods: {
    getTimeFromTs(ts) {
      return moment(+ts).format("HH:mm");
    },
    getTimeFromDate(strDate) {
      if (!strDate) return null;
      return moment(strDate).format("HH:mm");
    },
    getUtiCaricateOrScaricate(train) {
      if (train.tipoViaggio == "ARRIVO") {
        return train.numeroUtiScaricate;
      }else if (train.tipoViaggio =="PARTENZA"){
          return train.numeroUtiCaricate;
      }
       return "N/A";
    },
  },
};
</script>

<style scoped></style>
