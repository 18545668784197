<template>
  <component v-bind:is="homeComponent"></component>
</template>

<script>
import Dashboard from "./Dashboard";
import Lavorazioni from "./lavorazioni/lavorazioni";
import InventoryVisits from "@/views/yard/inventory/InventoryVisits"
import Gate from "@/views/rails/gate/Gate"
import Operations from "@/views/yard/inventory/Operations"
export default {
  name: "Home",
  data() {
    return {
      homeComponent: null,
    }
  },
  computed: {
    homeComponentName() {
      const roles = this.$store.getters['authentication/loggedUser'].roles;
      if (roles) {
        switch (roles.toString()){
          case "EXT_TOS_USER":
            return "Dashboard";
          case "GRUISTA":
            return "Lavorazioni";
          case "RAIL_GATE":
            return "Gate";
          case "MOBILE":
            return "Operations";
          default:
            return !roles.includes("USER") ? "InventoryVisits" : "Dashboard";
        }
      }
    },
  },
  mounted() {
    this.homeComponent = this.homeComponentName;
  },
  components: {
    Dashboard,
    Lavorazioni,
    InventoryVisits,
    Gate,
    Operations
  },
}
</script>